function changeImage(main, sub) {
  const changeImage = document.querySelector(main);
  let changeImageSrc = document.querySelector(main).getAttribute('src');
  const thumbs = [...document.querySelectorAll(sub)];

  thumbs.forEach((thumb) => {
    thumb.addEventListener('click', () => {
      let thumbSrc = thumb.getAttribute('src');
      thumb.setAttribute('src', changeImageSrc);
      changeImage.setAttribute('src', thumbSrc);
      changeImageSrc = thumbSrc;
    })
  });
}

const main = document.querySelector('main');

if (main.classList.contains('js-guestroom')) {
  changeImage('.js-change-image01', '.js-change-thumb01');
  changeImage('.js-change-image02', '.js-change-thumb02');
}

if (main.classList.contains('js-facility')) {
  changeImage('.js-change-image01', '.js-change-thumb01');
  changeImage('.js-change-image02', '.js-change-thumb02');
  // changeImage('.js-change-image03', '.js-change-thumb03');
}

if (main.classList.contains('js-sightseeing')) {
  changeImage('.js-change-image01', '.js-change-thumb01');
  changeImage('.js-change-image02', '.js-change-thumb02');
  changeImage('.js-change-image03', '.js-change-thumb03');
  changeImage('.js-change-image04', '.js-change-thumb04');
}

