const url = location.pathname;

if (url.indexOf('confirm') != -1) {
  const backButton = document.querySelector('button[name=submitBack]');
  const submitButton = document.querySelector('button[name=submit]');
  
  if(window.matchMedia('(max-width:769px)').matches) {
    backButton.style.height = '65px';
    submitButton.style.height = '65px';
    backButton.textContent = '';
    backButton.insertAdjacentHTML('afterbegin', '入力内容を<br>修正する');
  }
}
