import $ from 'jquery';

$('a[href^="#"]').click(function(){
  let adjust = 0;
  let speed = 400;
  let href= $(this).attr("href");
  let target = $(href == "#" || href == "" ? 'html' : href);
  let position = target.offset().top + adjust;
  $('body,html').animate({scrollTop:position}, speed, 'swing');
  return false;
});

$(function () {
  let urlHash = location.hash;
  if (urlHash) {
    $('body,html').stop().scrollTop(0);
    setTimeout(function(){
      let headerHight;
      if(window.matchMedia('(min-width:769px)').matches) {
        headerHight = 140;
      } else {
        headerHight = 40;
      }
      let targetOffPage = $(urlHash);
      let targetOffPosition = targetOffPage.offset().top - headerHight;
      $('body,html').stop().animate({scrollTop:targetOffPosition}, 400);
    }, 150);
  }
});